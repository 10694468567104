import logo from "./logo.gif";
import "./App.css";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />

                {/*
                <p>Nostalgia coming soon</p>
              */}
            </header>
        </div>
    );
}

export default App;
